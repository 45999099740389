import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from 'react-portable-text';

import { Grid, Typography, Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.workwaveBlue,
		height: '250px',
		padding: '0px',
		borderRadius: '8px',
		position: 'relative',
		transition: 'transform 0.25s',
		minWidth: '100%',
		marginBottom: '32px',
		marginTop: '-16em',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.10)',
		[theme.breakpoints.down('md')]: {
			marginTop: '-9em',
		},
		[theme.breakpoints.down('sm')]: {
			minWidth: '100%',
			height: 'auto',
			marginTop: '-6em',
		},
	},
	card: {
		textAlign: 'center',
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
	},
}));

export const PersonaCard = ({ card, accentColor }) => {
	const classes = useStyles();
	const { faIcon, personaIntro, _rawPersonaIntroBody, personaIntroImage } =
		card;

	return (
		<Card className={classes.root} elevation={0}>
			<CardContent
				style={{
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'space-between',
					minHeight: '40vh',
					padding: '0',
					height: 'auto',
				}}>
				<Grid container justifyContent='space-between' alignItems='flex-start'>
					<Grid
						item
						container
						direction='column'
						alignItems='center'
						justifyContent='center'
						style={{}}
						xs={12}
						md={6}>
						<GatsbyImage
							image={personaIntroImage?.asset?.gatsbyImageData}
							style={{
								margin: '-2px',
								maxHeight: '300px',
							}}
						/>
					</Grid>
					<Grid
						item
						container
						style={{ padding: '16px', margin: '0' }}
						xs={12}
						md={6}
						spacing={2}
						direction='column'
						justifyContent='center'
						alignItems='flex-start'>
						<Grid item container direction='row'>
							<FontAwesomeIcon
								icon={['fad', faIcon]}
								style={{
									height: '50px',
									width: '50px',
									border: `1px solid #E5EAF4`,
									background: '#E5EAF4',
									borderRadius: '8px',
									padding: '6px',
									color: accentColor[0]?.hexValue ?? '#002D5C',
								}}
							/>
							<Typography
								variant='h2'
								color='primary'
								style={{
									lineHeight: 1.4,
									marginLeft: '1rem',
								}}>
								{personaIntro}
							</Typography>
						</Grid>
						<Grid item>
							<PortableText
								content={_rawPersonaIntroBody}
								serializers={{
									normal: ({ children }) => (
										<Typography
											variant='body1'
											style={{
												color: '#4B5B69',
												lineHeight: 1.7,
												fontFamily: 'Roboto',
											}}>
											{children}
										</Typography>
									),
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};
