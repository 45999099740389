import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1.3rem",
    fontWeight: "bold",
    color: theme.workwaveBlue,
    textAlign: "center",
    lineHeight: "1",
    margin: "0 auto",
    position: "absolute",
    padding: "0 5px",
    left: "50px",
    right: "0",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
  },
  card: {
    // border: '1px solid #ECECEE',
    boxShadow: "0 10px 40px 0px rgba(0,0,0,0.10)",
    borderRadius: "8px",
    overflow: "hidden",
    cursor: "pointer",
    background: theme.white,
    width: "100%",
    transition: "transform 0.25s, box-shadow 0.75s",
    margin: "0 auto",
    position: "relative",
    border: "2px solid #E5EAF4",
    paddingRight: "30px",

    "&:last-of-type": {
      paddingRight: "0px",
    },
  },
  cardGrid: {
    marginBottom: "30px",
    paddingRight: "30px",
    "&:last-of-type": {
      paddingRight: "0px",
    },
  },
  cardMargin: {
    padding: "0 14px",
    marginBottom: "26px",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
}));

export const PersonaRibbon = ({ persona, handleClick }) => {
  const { personaHeroCard, title, accentColor, slug } = persona;
  const classes = useStyles();
  return (
    <Grid className={classes.cardMargin} item container xs={12} md={4}>
      <div className={classes.card}>
        <Grid
          onClick={(e) => handleClick(e, slug)}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <FontAwesomeIcon
            icon={["fad", personaHeroCard.faIcon]}
            style={{
              height: "50px",
              width: "50px",
              border: `1px solid #E5EAF4`,
              background: "#E5EAF4",
              padding: "6px",
              color: accentColor[0].hexValue ?? "#002D5C",
              // borderRadius: '20px 0 0 20px',
            }}
          />
          <Typography className={classes.title}>{title}</Typography>
        </Grid>
      </div>
    </Grid>
  );
};
