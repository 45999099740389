import React, { useState, useEffect, useRef, lazy, Suspense } from 'react';
import loadable from '@loadable/component';
import { navigate } from 'gatsby';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { BodyFeatureCard } from '../Industry/Features/BodyFeatureCard';
import { BodyFeature } from '../Industry/Features/BodyFeature';
import { CustomerLogos } from '../CustomerLogos';
import { PersonaCard } from './PersonaCard';
import { Testimonials } from '../Testimonials';
import { PersonaRibbon } from './PersonaRibbon';
import { WaveUpSVG } from '../WaveSVGs/WaveUpSVG';

const TestimonialCarousel = loadable(() =>
	import('../../components/Testimonials/TestimonialCarousel')
);

const useStyles = makeStyles((theme) => ({
	testimonialBackground: {
		backgroundSize: 'cover',
		height: '600px',
		width: '99vw',
		[theme.breakpoints.down('md')]: {
			height: '500px',
			width: '100vw',
		},
		[theme.breakpoints.down('sm')]: {
			height: '400px',
		},
	},
	testimonialContainer: {
		marginTop: '-35rem',
		[theme.breakpoints.down('md')]: {
			marginTop: '-30rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '-25rem',
		},
	},
	testimonialTitle: {
		color: theme.white,
		fontWeight: 700,
		padding: '2rem 0',
	},
}));

export const PersonaBody = ({ personas }) => {
	const classes = useStyles();

	//card array that gets modified on click. set brand new array to be manipulated on mount
	const [cardArray, setCardArray] = useState([]);

	useEffect(() => {
		setCardArray(personas.featureSection);
	}, []);

	const med = useMediaQuery('(max-width: 960px)');
	//const lg = useMediaQuery('(max-width: 1280px)');

	const {
		featureSection,
		featureHeader,
		accentColor,
		personaHeroCard,
		relatedPersonas,
		testimonial,
		customerLogos,
		customerLogosHeader,
		testimonialHeader,
		testimonialTitle,
		testimonialBackground,
	} = personas;
	const handlePersonaClick = (e, slug) => {
		e.preventDefault();
		navigate(`/${slug.current}`);
	};

	const firstSet = cardArray.length === 4 ? 2 : 3;
	const lastSet =
		cardArray.length === 4 ? -2 : cardArray.length === 6 ? -3 : null;
	return (
		<>
			<Container>
				<PersonaCard card={personaHeroCard} accentColor={accentColor} />
				<Grid container alignItems='center' justifyContent='space-between'>
					{relatedPersonas.map((persona, index) => (
						<PersonaRibbon
							key={index}
							persona={persona}
							handleClick={handlePersonaClick}
						/>
					))}
				</Grid>

				<BodyFeatureCard
					persona
					featureHeader={featureHeader}
					featureSection={featureSection}
					//setFeatureModalOpen={setFeatureModalOpen}
					accentColor={accentColor[0].hexValue ?? '#002D5C'}
				/>
			</Container>

			<Container>
				{cardArray.slice(0, firstSet).map((feature, index) => (
					<BodyFeature
						feature={feature}
						index={index}
						key={index}
						accentColor={accentColor[0].hexValue ?? '#002D5C'}
					/>
				))}
			</Container>

			<div
				className={classes.testimonialBackground}
				style={{
					backgroundImage: `url(${testimonialBackground?.asset.gatsbyImageData.images.fallback.src})`,
				}}
			/>
			<WaveUpSVG fill='#FFF' />
			<div className={classes.testimonialContainer}>
				<Testimonials
					header={testimonialTitle}
					testimonials={testimonial}
					background
				/>
			</div>
			<Container>
				<CustomerLogos
					customerLogosArray={customerLogos}
					customerLogosHeader={customerLogosHeader}
				/>
			</Container>

			{lastSet ? (
				<div className={classes.featureBackground}>
					<Container>
						{!med &&
							cardArray
								.slice(lastSet)
								.map((feature, index) => (
									<BodyFeature
										feature={feature}
										index={index}
										key={index}
										accentColor={accentColor[0].hexValue ?? '#002D5C'}
									/>
								))}
					</Container>
				</div>
			) : null}
		</>
	);
};
