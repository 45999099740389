import React, {
	useState,
	useEffect,
	useRef,
	lazy,
	Suspense,
	useContext,
} from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Hero } from '../components/General/Hero';
import { Resources } from '../components/General/Resources';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import { PersonaBody } from '../components/Personas/PersonaBody';
import { Related } from '../components/Industry/Related';
import { SEO } from '../components/SEO';
import FeatureThumbnailContext from '../context/FeatureThumbnailContext';
import { ThumbnailFeatureModal } from '../components/General/ThumbnailFeatureModal';
// import { Form } from '../components/ContactForm/Form';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	background: {
		background: theme.white,
	},
	heroVideo: {
		marginBottom: '5rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '3rem',
		},
	},
	personaHero: {
		display: 'none',
	},

	formWaveDown: {
		marginBottom: '-8rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '-4rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginBottom: '-3rem',
		},
	},
	formWaveUp: {
		marginBottom: '-8rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '-10rem',
		},
	},
	formCont: {
		padding: '24rem 0',
		[theme.breakpoints.down('md')]: {
			padding: '16rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '8rem 0',
		},
	},
}));

export default function Persona({ data: { personas }, location }) {
	const classes = useStyles();

	const med = useMediaQuery('(max-width: 960px)');
	//const lg = useMediaQuery('(max-width: 1280px)');

	const { modalOpen, setModalOpen, selectedThumbnail } = useContext(
		FeatureThumbnailContext
	);

	const {
		// _id,
		metaTitle,
		metaDescription,
		marketoId,
		pardotUrl,
		contactForm,
		hero,
		relatedIndustries,
		relatedHeader,
		formBgImage,
		resourceTitle,
		resources,
		_rawResourceBody,
	} = personas;

	return (
		hero && (
			<>
				<SEO title={metaTitle} description={metaDescription} />
				<Hero hero={hero} persona />

				<PersonaBody personas={personas} />

				<div>
					<WaveDownSVG height='213' width='100%' fill='#FFFFFF' />
				</div>

				<div
					className={classes.relatedBackground}
					style={{
						background: '#FFFFFF',
						paddingTop: personas.featureSection.length < 4 && '4rem',
					}}>
					<Container>
						<Related related={relatedIndustries} header={relatedHeader} />
					</Container>
				</div>

				<div>
					<WaveDownSVG height='213' width='100%' fill='#FFFFFF' />
				</div>

				<div
					className={classes.formCont}
					style={{
						backgroundImage: `url(${formBgImage?.asset?.gatsbyImageData?.images.fallback.src})`,
						backgroundSize: 'cover',
					}}>
					<Form
						formId={marketoId}
						pardotUrl={pardotUrl}
						contactForm={contactForm}
						privacy
						modal={false}
						location={location}
					/>
				</div>
				<WaveUpSVG height='213' width='100%' fill='#FFFFFF' />
				<Container>
					<Resources
						header={resourceTitle}
						resources={resources}
						subheader={_rawResourceBody}
					/>
				</Container>
				<div>
					<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
				</div>
				<ThumbnailFeatureModal
					open={modalOpen}
					setFeatureModalOpen={setModalOpen}
					wistiaLink={selectedThumbnail}
					feature
				/>
			</>
		)
	);
}

export const query = graphql`
	query ($slug: String!) {
		personas: sanityPersona(slug: { current: { eq: $slug } }) {
			_id
			title
			metaTitle
			metaDescription
			slug {
				current
			}
			accentColor {
				hexValue
			}
			hero {
				_rawContent
				backgroundImage {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
			}
			heroAlignCenter

			personaHeroCard {
				faIcon
				personaIntro
				_rawPersonaIntroBody

				personaIntroImage {
					asset {
						gatsbyImageData
					}
				}
			}
			relatedPersonas {
				personaHeroCard {
					faIcon
				}
				title
				accentColor {
					hexValue
				}
				slug {
					current
				}
			}
			featureSection {
				_key
				title
				cardTitle
				cardFaIcon
				cardIconColor {
					hexValue
				}
				bodySubHeader
				bodyHeader
				image {
					featureImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
				}
				videoVariant
				androidLink
				iosLink
				qbLink
				# svgVariant
				thumbnailVideoUrl
				ctaText
				ctaLink
				_rawBodyCopy
			}
			testimonialTitle
			testimonial {
				title
				header
				testimonialLogo {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
				company
				testimonialText
				nameAndTitle
				videoVariant
				image {
					asset {
						gatsbyImageData(
							fit: FILLMAX
							height: 450
							width: 775
							placeholder: BLURRED
						)
					}
				}
			}
			testimonialBackground {
				asset {
					gatsbyImageData(placeholder: BLURRED)
				}
			}
			relatedHeader
			relatedIndustries {
				faIcon
				accentColor {
					hexValue
				}
				title
				slug {
					current
				}
			}

			resourceTitle
			_rawResourceBody
			resources {
				title
				blurb
				image {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
				ctaText
				ctaLink
			}
			formBgImage {
				asset {
					gatsbyImageData(placeholder: BLURRED)
				}
			}
			contactForm {
				header
				privacyPolicy
			}
			marketoId
			pardotUrl
			customerLogosHeader
			customerLogos {
				logo {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
			}
		}
	}
`;
